/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fortawesome/fontawesome-free/css/all.css";

body {
  height: 100%
}

.c-pointer {
  cursor: pointer;
}

.markdown-clipboard-button {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.mensaje-chat h1 {
  font-size: 2rem;
  font-weight: bold;
}

.mensaje-chat h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.mensaje-chat h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.mensaje-chat p {
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.mensaje-chat ul {
  list-style-type: disc;
  margin-left: 2rem;
}

.mensaje-chat ol {
  list-style-type: decimal;
  margin-left: 2rem;
}

.mensaje-chat li {
  margin-bottom: 0.5rem;
}

.striped-table {}

.striped-table tbody tr:nth-child(odd) {
  background-color: #f6f3f3;
}

.striped-table tbody tr:nth-child(even) {
  background-color: white;
}

.mensaje-chat pre {
  background-color: #f3f3f3;
  padding: 1rem;
  overflow-x: auto;
}

.mensaje-chat code {
  background-color: #f3f3f3;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
}

.mensaje-chat blockquote {
  margin-left: 1rem;
  border-left: 2px solid #ccc;
  padding-left: 1rem;
  color: #666;
}

.mensaje-chat hr {
  border: 1px solid #ccc;
}

.mensaje-chat a {
  color: blue;
  text-decoration: underline;
}

.mensaje-chat img {
  /*max-width: 100%;*/
  /*height: auto;*/
  /*margin: 0 auto;*/
}

.mensaje-chat strong {
  font-weight: bold;
}

.mensaje-chat em {
  font-style: italic;
}

tr.no-check [type="checkbox"] {
  display: none;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: rotate 2s linear infinite;
}

.side-panel {
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
}

.open {
  transform: translateX(0) !important;
  transition: transform 0.5s ease-out;
}

.w-vista-previa-md {
  width: 30vw;
}


.w-vista-previa-lg {
  width: 50vw;
}

@media (max-width: 768px) {
  .w-vista-previa-lg {
    width: 90vw;
  }

  .w-vista-previa-md {
    width: 50vw;
  }
}


.tooltip-container::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.NgxEditor {
  height: 200px;
  overflow-y: scroll;
}

.NgxEditor__Content {
  min-height: 200px;
}

